import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import * as T from '../../common/Typography';
import Collapse from '../../common/Collapse';
import Layout from '../../Layouts';
import { fetchUserResults } from '../../../actions/users';
import BehavioralInsight from '../../common/BehavioralInsight';
import {
  TrainerResultsWrapper,
  StatsDiv,
  Paragraph,
  Bold,
  IndividualWrapper,
  Error,
  IndividualQuestion,
  NavigationWrapper,
  Arrow,
  QuestionSpan,
  Answer
} from './SurveyResults.style';
import Feedback from '../../common/Feedback';
import Toggle from '../../common/Toggle';
import ExportButton from '../../common/ExportButton';
import { Row, Col } from '../../common/Grid';
import { surveysHaveBehavQuestions } from '../../../constants';

const AnswerComponent = ({ answer }) => {
  if (moment(answer, '2022-03-18T09:32:29.723Z').isValid()) {
    return moment(answer).format('DD-MM-YYYY');
  } else if (typeof answer === 'object') {
    return Object.entries(answer).map(([key, val]) => (
      <Answer inner>
        <Bold>{key}:</Bold> {val}
      </Answer>
    ));
  } else {
    return answer;
  }
};
class SurveyResults extends Component {
  state = {
    toggle: 'left',
    responses: [],
    attendeesNumber: null,
    responsesNumber: null,
    activeIndex: 0
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    axios
      .get(`/api/session/${params.id}/${params.surveyType}/responses`)
      .then(({ data }) => {
        const { responses, attendeesNumber, responsesNumber } = data;
        this.setState({ responses, attendeesNumber, responsesNumber });
      });
  }

  clickToggle = direction => {
    this.setState({ toggle: direction });
  };

  handleNextPrev = e => {
    const { activeIndex, responses } = this.state;
    const inc = Number(e.target.id);
    let newIndex = 0;
    if (activeIndex + inc >= responses.length) {
      newIndex = 0;
    } else if (activeIndex + inc < 0) {
      newIndex = responses.length - 1;
    } else {
      newIndex = activeIndex + inc;
    }
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const {
      match: { params },
      role
    } = this.props;
    const {
      toggle,
      responses,
      attendeesNumber,
      responsesNumber,
      activeIndex
    } = this.state;

    const responseNumber = activeIndex + 1;
    const activeresponse = responses && responses[activeIndex];

    let panels = [];
    if (surveysHaveBehavQuestions.includes(params.surveyType)) {
      console.log('"=-=-="', '=-=-=');
      panels.push({
        title: 'Behavioural Analysis',
        content: (
          <BehavioralInsight
            sessionId={params.id}
            surveyType={params.surveyType}
            showFilters
            role={role}
            defaultFilters={{
              sessionId: params.id,
              surveyType: [params.surveyType]
            }}
            hiddenFields={['sessionType', 'localLead', 'trainer', 'surveyType']}
            surveyList={[params.surveyType]}
          />
        )
      });
    }

    return (
      <Layout>
        <TrainerResultsWrapper>
          <T.H1 mb={7}>Results</T.H1>{' '}
          <Toggle
            selected={toggle}
            leftText="Overall Results"
            rightText="Individual Responses"
            large
            style={{ margin: '20px auto' }}
            onClick={this.clickToggle}
            mb={6}
          />
          {toggle === 'left' ? (
            <>
              <StatsDiv>
                <Paragraph first>
                  <Bold>Number of Replies: {responsesNumber}</Bold> (out of{' '}
                  {attendeesNumber})
                </Paragraph>
                <Paragraph>
                  <Bold>
                    Completion Rate:{' '}
                    {attendeesNumber
                      ? Math.round((responsesNumber / attendeesNumber) * 100) +
                        '%'
                      : 0}
                  </Bold>
                </Paragraph>
              </StatsDiv>

              <Collapse items={panels} />
              <Row mt={5}>
                <Col w={[4, 6, 4]} ml="-10px">
                  <ExportButton
                    filters={{
                      sessionId: params.id,
                      surveyType: [params.surveyType]
                    }}
                    text="Export All Responses"
                    exportType="responses"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <IndividualWrapper>
              {responsesNumber === 0 ? (
                <T.P ml={2}>This session has no responses yet</T.P>
              ) : (
                <div style={{ paddingTop: '5px' }}>
                  <NavigationWrapper>
                    <Arrow
                      direction="right"
                      id="-1"
                      onClick={this.handleNextPrev}
                    />

                    <i>
                      {responseNumber} OF {responsesNumber}
                    </i>

                    <Arrow
                      direction="left"
                      id="+1"
                      onClick={this.handleNextPrev}
                    />
                  </NavigationWrapper>
                  {/* <Paragraph first align="center">
                    <Bold>Participant {activeresponse.PIN}</Bold>
                  </Paragraph> */}

                  {activeresponse?.data?.map(question => (
                    <IndividualQuestion key={question.questionText}>
                      <QuestionSpan>Q.</QuestionSpan>
                      {question.questionText}
                      <Answer>
                        <QuestionSpan>A.</QuestionSpan>

                        <AnswerComponent answer={question?.answer} />
                      </Answer>
                    </IndividualQuestion>
                  ))}
                </div>
              )}
            </IndividualWrapper>
          )}
        </TrainerResultsWrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  results: state.results,
  role: state.auth.role
});

export default connect(mapStateToProps, { fetchUserResults })(SurveyResults);
